













import Component from 'vue-class-component';

import UiPageHeader from '@/components/ui/UiPageHeader.vue';

import { BaseVue } from '../../BaseVue';
import Accounting from '../../components/org/AccountingSettings.vue';

@Component({
  components: {
    Accounting,
    UiPageHeader,
  },
})
export default class AccountingClass extends BaseVue {
  public selectedTab = 0;
}
